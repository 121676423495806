import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Fetch } from 'src/app/services/fetch.service';
import { TenantService } from 'src/app/services/tenant.service';
import { ToasterService } from 'src/app/services/toaster.service';
import { MenuItem } from 'src/app/types/menu';

import { assetmenu, navmenu, personamenu } from '../menu.json';
import { SubstitutionService } from 'src/app/services/substitution.service';

@Injectable({
    providedIn: 'root'
})
export class NavMenuService {

    public navMenu$ = new BehaviorSubject<MenuItem[]>(null);

    // What purpose do these serve?
    public personaMenu$ = new BehaviorSubject<MenuItem[]>(null);
    private assetMenu$ = new BehaviorSubject<MenuItem[]>(null);

    constructor(
        private readonly fetch: Fetch,
        private readonly toaster: ToasterService,
        private readonly tenant: TenantService,
        private readonly substitutor: SubstitutionService
    ) {
        tenant.subscribe(t => t && this.loadNavMenu());
    }

    private async loadNavMenu() {
        let { navmenu, assetmenu, personamenu } = await this.fetch.get<any>("/api/portal/uidata", {}, true).catch(err => {
            window.dtrum?.reportCustomError("Load Error", "Navigation Load Failed", JSON.stringify(err), true);
            return { navmenu: null, assetmenu: null, personamenu: null };
        });

        const parseMenuItems = (items: MenuItem[]) => {
            let itemMap = {};
            items.forEach(i => {
                itemMap[i.id] = i;
                i.children = [];
                i['isVisible'] = () => {
                    if (i['state']?.includes('hidden') || !i.label) return false;
                    if (!i.label.includes("{")) return true;
                    const result = this.substitutor.resolve(i.label);
                    return !result.includes("(N/A)");
                }
                i['labelTemplate'] = () => {
                    return this.substitutor.resolve(i.label) || i.longname;
                }
                // ??? What purpose does this serve?
                //i.data = getUrlData(i.url?.split('?').pop() || '');

                if (i.url?.length > 2) {
                    i['link'] = this.substitutor.resolve(i.url);
                    if (i.url.startsWith('http'))
                        i['linkTarget'] = "_blank";
                }

                // if (mi.url?.startsWith("http")) {
                //     // TODO: handle
                //     mi.url = mi['link'] = mi.url;
                // }
            });

            items.forEach(m => {
                itemMap[m.parent]?.children?.push(m);
            });
            items.sort((a, b) => a.order - b.order);
            return items;
        }

        this.navMenu$.next(parseMenuItems(navmenu));
        this.personaMenu$.next(parseMenuItems(personamenu));
        this.assetMenu$.next(parseMenuItems(assetmenu));


        if (!navmenu || navmenu.length == 0) {
            this.toaster.err("Failed to load tenant", "There was a fatal issue loading your tenant. Please contact your Dynatrace Representative to get this fixed.");
            return;
        }
    }

    getDefaultTabs(dto: string, extraIds: number[]) {
        // const menuItemsList = this.treeToList(this.menuItemsTree).filter(i => !!(i?.label));
        const items = this.assetMenu$.value || [];
        return {
            fixedTabs: items?.filter(item => item.dtoName == dto && item.state == "fixed") || [],
            defaultTabs: items?.filter(item => item.dtoName == dto && (item.state == "default" || extraIds.includes(item.id))) || []
        };
    }

    getAssetMenu(dto: string) {
        const dtoMenus = this.assetMenu$.value?.filter(item => item.dtoName == dto) || [];

        // The pageMenuItems contains all menu items relevant to the current mode and DTO.
        const pageMenuItems = [...dtoMenus];//, ...allMenus];
        // The menuItemsTree is the version that the menu component will render in the UI.
        return this.listToTree(pageMenuItems, -2);
    }

    private listToTree(menuItems: MenuItem[], parentId: number): MenuItem[] {
        const tree: MenuItem[] = [];

        menuItems
            .filter(mi => mi.parent == parentId)
            .sort((a, b) => a.order - b.order)
            .forEach(mi => {
                if (mi.label.startsWith('|')) tree.push({ id: -1, separator: true } as any);

                tree.push({
                    ...mi,
                    ...{
                        label: mi.label.replace('|', ''),
                        children: this.listToTree(menuItems, mi.id)
                    }
                });
                /*  tree.push({
                      id: mi.id,
                      url: mi.url,
                      label: mi.label.replace('|', ''),
                      icon: mi.icon,
                      separator: false,
                      items: this.listToTree(menuItems, mi.id)
                  }); */

                if (mi.label.endsWith('|')) tree.push({ id: -1, separator: true } as any);
            });

        return tree;
    }
}
