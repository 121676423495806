import { Injectable } from '@angular/core';
import { ReferenceType } from 'src/dto/dto';
import { UserService } from 'src/app/services/user.service';
import { TenantService } from 'src/app/services/tenant.service';

@Injectable({
    providedIn: 'root'
})
export class SubstitutionService {

    constructor(
        private readonly user: UserService,
        private readonly tenant: TenantService
    ) { }

    /*  Parts of strings can be substituted in accordance with the following syntax:
    *
    * ! These deserve to be reviewed
    * ! {{<anyDTO>.<dtoProp>}}
    * ! Finds the most recently selected DTO of the specified type in the current
    * ! crumbtrail. Defaults to the default DTO of the specified type for the user.
    * ! EX: {{project.name}} => "The project for the goal I just selected"
    * !
    * ! {{asset.<dtoProp>}}
    * ! Refers to the currently active (i.e. selected) DTO.
    * ! EX: {{asset.name}}   => "Pete's AIM gateway observability request"
    *
    * {{$user.<userProp>}}
    *     Refers to the currently logged on user (ElevateUser)
    *
    * {{$tenant.<tenantProp>}}
    *     Refers to the currently selected tenant (ElevateTenant).
    *
    * {{$<anyDTO>.<dtoProp>}}
    *     Finds the default DTO of the specified type for this user. Defaults to empty.
    *     EX: {{$project.name}} => "That project I always check first"
    *
    * Note that only base DTO, ElevateUser or ElevateTenant properties are available.
    *
    * NOTE: All panels always get 'asset' -> "project.123"
    */
    resolve(value: string, data?: any): string {
        // const currentTrail = this.mode?.trail || [];

        // const getFromCrumbtrail = (type: string) => {
        //     type = type.toLowerCase();
        //     return currentTrail.filter(a => a.type == type).pop()?.asset;
        // };
        const getFromFavorites = (type: string) => {
            const refType = ReferenceType[type.toUpperCase()];
            const fav = this.user.favorites?.find(f => f.targetType == refType && f.data === "DEFAULT");
            return fav ? {
                id: fav.target,
                parentId: fav.source,
                name: fav.description,
                dto: fav.targetType,
                description: fav.name,
                idx: "",
                icon: ""
            } : null;
        };
        const getFromContext = (type: string) => {
            return type == "user" ? this.user
                // TODO: tenant.value != ElevateTenant
                : type == "tenant" ? this.tenant.value
                // : type == "asset" ? currentTrail[currentTrail.length - 1]?.asset
                : null;
        };

        const tag = /\{\{(?<scope>[^.]+)\.(?<type>[^.]+)\.(?<prop>[^.|]+)\|?(?<default>[^}]+)?\}\}/;

        let source = null;
        let match: RegExpExecArray = null;
        let replacement: string = "";
        let i = 0;  // To relieve Andrew's anxiety about loops.

        // console.log("Substituting", { value, source })
        while ((match = tag.exec(value)) && i++ < 100) {
            switch (match.groups["scope"]) {
                case "current":
                    source =  // getFromCrumbtrail(match.groups["type"]) ||
                        getFromFavorites(match.groups["type"]);
                    break;

                case "default":
                    source = getFromFavorites(match.groups["type"]);
                    break;

                case "context":
                    source = getFromContext(match.groups["type"]);
                    break;

                default:
                    source = null;
                    break;
            }

            replacement = source
                ? source[match.groups["prop"]]
                : (match.groups["default"] || "(N/A)");
            value = value.replace(match[0], replacement);
        }

        // !!!
        value = value.replace(/\{(?:\$(?<scope>(?:user|tenant)))\.(?<property>[^}|]+)(?:\|(?<default>[^}]+))?\}/g, ((m, scope, key, fallback) => {
            return window[scope]?.[key] || fallback;
        }))

        return value;
    }
}
