import { Injectable, isDevMode } from '@angular/core';
import { BehaviorSubject } from "rxjs";
import { Fetch } from './fetch.service';
import { UserService } from './user.service';
import { TenantService } from 'src/app/services/tenant.service';

export type Approval = {
    contact_email: string,
    contact_id: string,
    contact_name: string,
    contact_status: "azure" | "dynatrace" // ???
    id: number
}

export type EventNotification = {
    dto: string,
    endDate: string,
    id: number,
    name: string,
    progress: number,
    rag: string,
    roots: unknown,
    startDate: string,
    status: string
}

export type NotificationEntry = {
    notify_id: number,
    notify_outbound_account: string,
    notify_outbound_message: `${'Responsible'|'Accountable'|'Consulted'|'Informed'}${string}`,
    notify_outbound_subject: string,
    /**
     * 1 - email
     * 2 - slack
     */
    notify_outbound_type: 1 | 2,
    notify_queue_entrydate: string,
    notify_status: "SUCCESS",
}

export type Notification = {
    approvals: Approval[],
    following: EventNotification[];
    meetings: EventNotification[];
    owner: EventNotification[];
    responsible: EventNotification[];
    notifications: NotificationEntry[]
}

@Injectable({
    providedIn: 'root'
})
export class NotificationService extends BehaviorSubject<Notification> {

    private readonly interval = 3 * 60 * 1000;

    public appVersion$ = new BehaviorSubject<boolean>(false);

    constructor(private fetch: Fetch, private tenant: TenantService, private user: UserService) {
        super(null);

        tenant.subscribe(t => {
            if (this.timeout)
                clearTimeout(this.timeout);
            this.refresh();
        });
    }

    private timeout;
    private refresh() {
        if (!this.user.value?.email || !this.tenant.value) return;

        this.fetch.get(`/api/portal/notifications`, {}, true)
            .then(v => {
                const value = {};
                Object.entries(v).forEach(([k,v]) => {
                    value[k] = v.filter(e => Object.keys(e).length > 0)
                })
                this.next(value as any);
            })
            .catch(err => {
                console.error("Error getting notifications", err)
            });

        this.timeout = setTimeout(() => {
            this.refresh();
        }, this.interval);
    }
}
