<!-- This component is loaded when a bad path is entered in the
hash routing in the URL. E.g. https://localhost:4200/#/MyDogAteAPie -->
<div *ngIf="visible && isNewVersion">
    <h1>404</h1>
    <h2>Oops, the page you're looking for might not exist.</h2>
    <p>
        We released a new version of the website while you were
        online, so you may want to refresh the page to make sure
        links and pages are up to date.
        <br />
        <br />
        Alternatively, you can head back to the homepage
        to keep browsing through the website.
        <br />
        If you refresh and still see that something is broken,
        please report a problem by contacting your Dynatrace
        representative.
        <br />
    </p>
    <a href="#/Landing">
        <button mat-button color="primary">Take me home</button>
    </a>
</div>
<div *ngIf="visible">
    <h1>404</h1>
    <h2>Oops, the page you're looking for could not be found.</h2>
    <p>
        You may want to head back to the homepage.
        <br/>
        If you think something is broken, report a problem.
        <br/>

    </p>
    <a href="#/Landing">
        <button mat-button color="primary">Take me home</button>
    </a>
</div>
